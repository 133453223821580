import React, { Component } from 'react';
import { Image } from '../../BlockLibrary/Common/Image';
import { RichText } from '../../richTextOptions';
import { ContentfulBlockImage } from '../../BlockLibrary/ContentfulBlockImage';

const contentful = require("contentful");

const client = contentful.createClient({
     space: process.env.CONTENTFUL_SPACE_ID,
     accessToken: process.env.CONTENTFUL_PREVIEW_TOKEN,
     host: 'preview.contentful.com'
});

class PreviewPgHome extends Component {
     constructor(props) {
          super(props);

          this.state = {
               lastestArticle: null,
               pgSidebarImage: null
          }
     }

     componentDidMount() {
          client
               .getEntries({
                    'content_type': 'pgArticle',
                    order: '-sys.createdAt', // order descending by creation timestamp
                    'include': 10,
                    'limit': 1
               })
               .then(entries => {
                    this.setState({ lastestArticle: entries.items[0] })
               }).catch(err => {
                    // console.log(err)
               });

          client.getEntry('4sTOhD2BB2We4TYk0IzfKJ') // Page > Pet Gazette
               .then(entry => {
                    this.setState({ pgSidebarImage: entry.fields.rows[0].fields })
               })
               .catch(err => {
                    // console.log(err)
               });
     }

     render() {
          const article = this.state.lastestArticle;

          if (article) {
               return (
                    <section data-tabind={2} className={`pg-article-content section-tabIndex-2`}>
                         <div className="container">
                              <div className="row">
                                   <div className="col-lg-9">
                                        <div className="pg-article-wrapper">
                                             <div className="pg-title-social">
                                                  {article.fields.articleName && <h1>{article.fields.articleName}</h1>}
                                                  <div className="social">
                                                       <div className="addthis_toolbox">
                                                            <div className="custom_images">
                                                                 <a className="addthis_button_linkedin animation">
                                                                      <img src="/images/pet-gazette/linkedin.png" alt="Share to Linkedin" />
                                                                 </a>
                                                                 <a className="addthis_button_facebook animation">
                                                                      <img src="/images/pet-gazette/facebook.png" alt="Share to Facebook" />
                                                                 </a>
                                                                 <a className="addthis_button_twitter animation">
                                                                      <img src="/images/pet-gazette/twitter.png" alt="Share to Twitter" />
                                                                 </a>
                                                                 <a className="addthis_button_more">
                                                                      <img src="/images/pet-gazette/youtube.png" alt="More..." />
                                                                 </a>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                             {article.fields.body && <RichText data={article.fields.body}></RichText>}
                                        </div>
                                   </div>
                                   <div className="col-lg-3 sidebar-content">
                                        <div className="image-layout">
                                             <ContentfulBlockImage {...this.state.pgSidebarImage} />
                                             {/* <a href="/veterinarians/"><img src="/images/hrpg.jpg" alt="Add pet insurance to your voluntary benefit package" /></a> */}
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </section>
               )
          } else {
               return <></>
          }
     }
}

export default PreviewPgHome;
