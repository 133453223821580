import React from 'react';
import { connect } from "react-redux";
import PreviewPage from '../components/PreviewLibrary/Page';
import { getPreviewPage } from '../state/app';
import { setOmCode, setPhoneLocalStorage } from '../components/utilities';
import Pusher from 'pusher-js';

const contentful = require("contentful");

const client = contentful.createClient({
     space: process.env.CONTENTFUL_SPACE_ID,
     accessToken: process.env.CONTENTFUL_PREVIEW_TOKEN,
     host: 'preview.contentful.com'
});

class PreviewPageTemplate extends React.Component {
     constructor(props) {
          super(props);
          this.state = {
               loading: true,
               sysId: this.props['*'],
          }
     }

     componentDidMount() {
          const brower = typeof window !== `undefined`;

          const _this = this;
          _this.setState({ ..._this.state, loading: true });
          client
               .getEntries({
                    'sys.id': _this.state.sysId,
                    'include': 10
               })
               .then(entries => {
                    setTimeout(function () {
                         _this.setState({ ..._this.state, loading: false });
                    }, 1000)
                    if (entries.total > 0) {
                         _this.props.setPreviewIncludes(entries.includes);
                         _this.props.setPreviewPage(entries.items[0]);
                    }
                    if (brower) {
                         var om = entries.items[0].fields.trackingCode;
                         var phone = entries.items[0].fields.specificPhone;
                         setOmCode(om, _this.props.location.href);
                         setPhoneLocalStorage(phone, _this.props.location.href);

                         var getOmValue = localStorage.getItem("Petinsurance_OM");
                         var getPhoneValue = localStorage.getItem("Petinsurance_TrackingPhone");
                         _this.props.setTrackingCode(getOmValue);
                         _this.props.setTrackingPhone(getPhoneValue);
                    }
               }).catch(err => {
                    // console.log(err)
               });

          Pusher.logToConsole = true;

          var pusher = new Pusher('6e16fb2a37840ec116dc', {
               cluster: 'mt1',
               forceTLS: true
          });

          var channel = pusher.subscribe('my-channel');
          channel.bind('pageReload', function (data) {
               _this.setState({ ..._this.state, loading: true });
               client
                    .getEntries({
                         'sys.id': _this.state.sysId,
                         'include': 10
                    })
                    .then(entries => {
                         setTimeout(function () {
                              _this.setState({ ..._this.state, loading: false });
                         }, 1000)
                         if (entries.total > 0) {
                              _this.props.setPreviewIncludes(entries.includes);
                              _this.props.setPreviewPage(entries.items[0]);
                         }
                         if (brower) {
                              var om = entries.items[0].fields.trackingCode;
                              var phone = entries.items[0].fields.specificPhone;
                              setOmCode(om);
                              setPhoneLocalStorage(phone);

                              var getOmValue = localStorage.getItem("Petinsurance_OM");
                              var getPhoneValue = localStorage.getItem("Petinsurance_TrackingPhone");
                              _this.props.setTrackingCode(getOmValue);
                              _this.props.setTrackingPhone(getPhoneValue);
                         }
                    }).catch(err => {
                    });
          });
     }

     render() {
          return (
               <>
                    {
                         this.props.previewPage != null && (
                              <PreviewPage isLoading={this.state.loading}></PreviewPage>
                         )
                    }
               </>
          )
     }
}

const mapStateToProps = (state) => {
     return {
          previewPage: getPreviewPage(state)
     }
}

const mapDispatchToProps = dispatch => {
     return {
          setPreviewIncludes: (includes) => dispatch({ type: `PREVIEW_STATE_INCLUDES`, payload: includes }),
          setPreviewPage: (page) => dispatch({ type: `PREVIEW_STATE_PAGE`, payload: page }),
          setTrackingCode: (trackingCode) => dispatch({ type: `TRACKING_CODE`, payload: trackingCode }),
          setTrackingPhone: (trackingPhone) => dispatch({ type: `TRACKING_PHONE`, payload: trackingPhone }),
     }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewPageTemplate);